<template>
  <v-container fluid class="fullscreen">
  <v-row no-gutters>
    <v-col cols="12" md="3" class="pt-1 pl-5">
      <v-autocomplete
        v-model="userTypeAll"
        item-text="userType"
        :items="userTypeAllList"
        name="userType"
        label="User Type"
        return-object
        outlined
        required
        dense
        small
        filled
        auto-select-first
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="12" class="ml-5">
      <v-row no-gutters>
        <v-col cols="12" md="2" class="ml-5">
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <h3>Money Service</h3>
            </v-col>
            <v-col cols="12" md="12">
              <v-card outlined class="mt-2 pb-2 pl-2">
                <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.moneyservice" label="Money Service" hide-details></v-checkbox>
              <v-row no-gutters>
              <v-col cols="6" md="6" class="pl-3">
                <v-checkbox color="green" @change="uiMethod()" v-model="uiAccept.msedit" label="Edit" hide-details></v-checkbox>
              </v-col>
               <v-col cols="6" md="6">
                <v-checkbox color="red" @change="uiMethod()" v-model="uiAccept.msdelete" label="Delete" hide-details></v-checkbox>
              </v-col>
              </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="2" class="ml-5">
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <h3>Voucher</h3>
            </v-col>
            <v-col cols="12" md="12">
              <v-card outlined class="mt-2 pb-2 pl-2">
                <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.newvoucher" label="New Voucher" hide-details></v-checkbox>
              </v-card>
            </v-col>
            <v-col cols="12" md="12">
              <v-card outlined class="mt-2 pb-2 pl-2">
                <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.voucherlist" label="Voucher List" hide-details></v-checkbox>
                <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.voucherall" label="Voucher All" hide-details></v-checkbox>
                <v-row no-gutters>
              <v-col cols="6" md="6" class="pl-3">
                <v-checkbox color="green" @change="uiMethod()" v-model="uiAccept.vlchange" label="Change" hide-details></v-checkbox>
              </v-col>
               <v-col cols="6" md="6">
                <v-checkbox color="green" @change="uiMethod()" v-model="uiAccept.vlrefund" label="Refund" hide-details></v-checkbox>
              </v-col>
              <v-col cols="6" md="6" class="pl-3">
                <v-checkbox color="green" @change="uiMethod()" v-model="uiAccept.vledit" label="Edit" hide-details></v-checkbox>
              </v-col>
              <v-col cols="6" md="6">
                <v-checkbox color="red" @change="uiMethod()" v-model="uiAccept.vldelete" label="Delete" hide-details></v-checkbox>
              </v-col>
              </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="12">
              <v-card outlined class="mt-2 pb-2 pl-2">
                <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.receivevoucher" label="Receive Voucher" hide-details></v-checkbox>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="2" class="ml-5">
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <h3>Payment</h3>
            </v-col>
            <v-col cols="12" md="12">
              <v-card outlined class="mt-2 pb-2 pl-2">
                <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.receipt" label="Receipt" hide-details></v-checkbox>
              <v-row no-gutters>
              <v-col cols="6" md="6" class="pl-3">
                <v-checkbox color="green" @change="uiMethod()" v-model="uiAccept.receiptedit" label="Edit" hide-details></v-checkbox>
              </v-col>
               <v-col cols="6" md="6">
                <v-checkbox color="red" @change="uiMethod()" v-model="uiAccept.receiptdelete" label="Delete" hide-details></v-checkbox>
              </v-col>
              </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" md="12">
              <v-card outlined class="mt-2 pb-2 pl-2">
                <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.commission" label="Commission" hide-details></v-checkbox>
              <v-row no-gutters>
              <v-col cols="6" md="6" class="pl-3">
                <v-checkbox color="green" @change="uiMethod()" v-model="uiAccept.comedit" label="Edit" hide-details></v-checkbox>
              </v-col>
               <v-col cols="6" md="6">
                <v-checkbox color="red" @change="uiMethod()" v-model="uiAccept.comdelete" label="Delete" hide-details></v-checkbox>
              </v-col>
              </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" md="12">
              <v-card outlined class="mt-2 pb-2 pl-2">
                <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.payment" label="Payment" hide-details></v-checkbox>
              <v-row no-gutters>
              <v-col cols="6" md="6" class="pl-3">
                <v-checkbox color="green" @change="uiMethod()" v-model="uiAccept.payedit" label="Edit" hide-details></v-checkbox>
              </v-col>
               <v-col cols="6" md="6">
                <v-checkbox color="red" @change="uiMethod()" v-model="uiAccept.paydelete" label="Delete" hide-details></v-checkbox>
              </v-col>
              </v-row>
              </v-card>
            </v-col>

          </v-row>
        </v-col>

        <v-col cols="12" md="2" class="ml-5">
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <h3>Reports</h3>
            </v-col>
            <v-col cols="12" md="12">
              <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.moneyreport" label="Money Report" hide-details></v-checkbox>
              <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.paymentreport" label="Payment Report" hide-details></v-checkbox>
              <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.balancereport" label="Balance Report" hide-details></v-checkbox>
              <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.debitreport" label="Debit/Credit Report" hide-details></v-checkbox>
              <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.customerreport" label="Customer Report" hide-details></v-checkbox>
              <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.userreport" label="User Report" hide-details></v-checkbox>
              <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.airlinereport" label="Airline Report" hide-details></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="2" class="ml-5">
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <h3>Setting</h3>
            </v-col>
            <v-col cols="12" md="12">
                <v-card outlined class="mt-2 pb-2 pl-2">
                <v-checkbox color="black" @change="uiMethod()"  v-model="uiAccept.setting" label="Setting" hide-details></v-checkbox>
              <v-row no-gutters>
              <v-col cols="6" md="6" class="pl-3">
                <v-checkbox color="green" @change="uiMethod()"  v-model="uiAccept.sedit" label="Edit" hide-details></v-checkbox>
              </v-col>
               <v-col cols="6" md="6">
                <v-checkbox  color="red" @change="uiMethod()"  v-model="uiAccept.sdelete" label="Delete" hide-details></v-checkbox>
              </v-col>
              </v-row>
              </v-card>
              <v-card outlined class="mt-2 pb-2 pl-2">
                <v-checkbox  color="black" @change="uiMethod()" v-model="uiAccept.contacts" label="Contacts" hide-details></v-checkbox>
              </v-card>
              <v-card outlined class="mt-2 pb-2 pl-2">
                <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.account" label="Account" hide-details></v-checkbox>
              <v-row no-gutters>
              <v-col cols="6" md="6" class="pl-3">
                <v-checkbox color="green" @change="uiMethod()" v-model="uiAccept.accedit" label="Edit" hide-details></v-checkbox>
              </v-col>
               <v-col cols="6" md="6">
                <v-checkbox color="red" @change="uiMethod()" v-model="uiAccept.accdelete" label="Delete" hide-details></v-checkbox>
              </v-col>
              </v-row>
              </v-card>
              <v-card outlined class="mt-2 pb-2 pl-2">
                <v-checkbox color="black" @change="uiMethod()" v-model="uiAccept.type" label="Type" hide-details></v-checkbox>
              <v-row no-gutters>
              <v-col cols="6" md="6" class="pl-3">
                <v-checkbox color="green" @change="uiMethod()" v-model="uiAccept.typeedit" label="Edit" hide-details></v-checkbox>
              </v-col>
               <v-col cols="6" md="6">
                <v-checkbox color="red" @change="uiMethod()" v-model="uiAccept.typedelete" label="Delete" hide-details></v-checkbox>
              </v-col>
              </v-row>
              </v-card>
              <v-card outlined class="mt-2 pb-2 pl-2">
                <!-- <v-checkbox  color="black" @change="uiMethod()" v-model="uiAccept.type" label="Type" hide-details></v-checkbox> -->
                <v-checkbox  color="black" @change="uiMethod()" v-model="uiAccept.company" label="Company" hide-details></v-checkbox>
                <v-checkbox  color="black" @change="uiMethod()" v-model="uiAccept.airline" label="Airline" hide-details></v-checkbox>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        
      </v-row>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
import uaService from "../service/UserAccountService";
export default {
  data: () => ({
    userTypeAllList:['STAFF','ACCOUNTANT','ADMIN','MSSTAFF'],
    userTypeAll:"ADMIN",
    uiAccept:{}
  }),
  props: {},
  mounted: function () {
    //this.getUserType();
    this.getUi();
  },
  methods: {
    // getUserType: function () {
    //   uaService
    //     .getUserAccounts("ALL")
    //     .then((response) => {
    //       this.userTypeAllList.splice(0, this.userTypeAllList.length);
    //       this.userTypeAllList.push(...response);
    //       this.userTypeAll = this.userTypeAllList[0];
    //     })
    //     .catch((err) => {
    //       this.$swal("Failed", err.response.data.message, "error");
    //     });
    // },
    getUi: function () {
      uaService
        .getUi(this.userTypeAll)
        .then((response) => {
          this.uiAccept = Object.assign({},response);
          console.log(this.uiAccept);
        })
        .catch((err) => {
          this.$swal("Failed", err.response.data.message, "error");
        });
    },
    uiMethod: function () {
      uaService
        .updateUi(this.uiAccept)
        .then((response) => {})
        .catch((error) => {
          this.$swal("Fail!", error.response.data.message, "error");
        });
    },
  },
  watch: {
    userTypeAll() {
      this.getUi();
    },
    // uiAccept:{
    //   handler(val){
    //     uaService
    //     .updateUi(this.uiAccept)
    //     .then((response) => {
    //     })
    //     .catch((err) => {
    //       this.$swal("Failed", err.response.data.message, "error");
    //     });
    //  },
    //  deep: true
    // }
  },
  components: {},
};
</script>
<style scoped>
.fullscreen {
  height: 92vh;
}
</style>